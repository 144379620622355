import React from "react"
import { useStaticQuery, Link, graphql } from "gatsby"
import headerStyles from "./header.module.css"

  export default function Header(props) {
    const data = useStaticQuery(
      graphql`
        query {
          site {
            siteMetadata {
              title
            }
          }
        }
      `
    )
    return <div className={headerStyles.header}>
            <div className={headerStyles.title}>
            <Link className={headerStyles.title} to="/top"><h1> {data.site.siteMetadata.title}</h1></Link>
            </div>
            <div className={headerStyles.credit}>
              <h3 className={headerStyles.creditupper}>帝国書院
              <span className={headerStyles.creditright}>× 都市構造可視化推進機構</span></h3>
              <h5 className={headerStyles.creditunder}>監修：根元一幸（神奈川大特任准教授、元神奈川県立高校教諭）</h5>
            </div>
            <div>
            <Link className={headerStyles.link} to="/top"><font className={headerStyles.linkfont}>HOME</font></Link>
            </div>
          </div>
  }
