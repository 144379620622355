import React from "react"
import { Link } from "gatsby"
import selectbarStyles from "./Selectbar.module.css"
import Localstragetest
from "../pages/localstragetest.js"
import Citysetting1 from '../components/citysetting/citysetting1';
import Citysetting2 from '../components/citysetting/citysetting2';
import Citysetting3 from '../components/citysetting/citysetting3';
import Citydisplay1 from '../components/citysetting/citydisplay1';

  export default function Selectbar(props) {
    return <div className={selectbarStyles.outbox}>
            <div className={selectbarStyles.step1}>
              <div>
                <Link to="/themeset">
                  <h2 className={selectbarStyles.title}>STEP1</h2>
                </Link>
              <h4 className={selectbarStyles.text}>調べたいテーマを探す</h4>

            <div className={selectbarStyles.themeoutbox}>
              <div>
                <div className={selectbarStyles.modalopen}>
                  <a href="/sdgs">
                    テーマで探す
                  </a>
                </div>
              </div>
              <div>
                <div className={selectbarStyles.modalopen}>
                  <a href="/sdgs">
                    教科書の目次で探す
                  </a>
                </div>
              </div>
              <div>
                <div className={selectbarStyles.modalopen}>
                  <a href="/sdgs">
                    SDGsで探す
                  </a>
                </div>
              </div>
              <div>
                <div className={selectbarStyles.modalopen}>
                  <a href="/sdgs">
                    出典で探す
                  </a>
                </div>
              </div>
            </div>
          </div>


            </div>
            <div className={selectbarStyles.step2}>
            <Link to="/cityset">
            <h2 className={selectbarStyles.title}>{props.step2Text}</h2>
            </Link>
              <div className={selectbarStyles.step2box}>
                <div className={selectbarStyles.step2boxup}>
                  <div className={selectbarStyles.step2boxupleft}>
                    <h4 className={selectbarStyles.text}>①典型例を表示する</h4>
                  </div>
                  <div>
                    <Link className={selectbarStyles.step2link} to="https://univ.kashika.net/20201022-2/"><font className={selectbarStyles.linkfont}>□　典型例の都市　　　</font></Link>
                  </div>
                </div>
                <div>
                  <h4 className={selectbarStyles.text}>②自分で調べたい都市を探す（３つまで選択可能）</h4>
                  <div className={selectbarStyles.cityset}>
                    <Citysetting1 />
                    <details>
                      <summary>2以上の自治体を選ぶ</summary>
                      <div style={{display: "flex", justifyContent: "center"}}>
                      <Citysetting2 />
                      <Citysetting3 />
                      </div>
                    </details>
                    </div>
                </div>
              </div>

            </div>
            <div className={selectbarStyles.step3}>
              <h2 className={selectbarStyles.title3}>{props.step3Text}</h2>
              <Link className={selectbarStyles.link} to="/105"><font className={selectbarStyles.linkfont}>{props.linkText}</font></Link>
            </div>
          </div>
  }
