import React from "react"
import styles from "./top.module.css"
import Layout from "../components/layout.tsx"

import Headerimg from "../components/headerimg"
import Header from "../components/header"
import DevHeader from "../components/header/devheader"
import Selectbar from "../components/Selectbar"
import Hanrei from "../components/hanrei-img"
import Cesiumtab from "../containers/cesiumtab"



export default function About({data}) {
  console.log(data)
  return (
    <div>
      <DevHeader />
      <Header />
      <Headerimg
        src="https://www.teikokushoin.co.jp/textbook/tenji/img/c108.jpg" alt="top image"
      />
      
      <Selectbar
        step1Title="STEP1"
        step1Text="調べたいテーマを探す"
        step2Text="STEP2"
        step3Text="STEP3"
        linkText="描画"
        link2home="/sample"
      />



      <div style={{textAlign:"center"}}>
          <img src="https://www.teikokushoin.co.jp/top/anniversary/images/index_img1.png" />
      </div>

    </div>
  )
}

